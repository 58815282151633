import React from "react";
import Slider from "react-slick";

export default function ServiceSlide() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,  
    autoplaySpeed: 3000,  
  };
  return (
    <Slider {...settings}>
      {/* first */}
      <div className="text-white p-3">
        <h3>
          <b>Web Development</b>
        </h3>
        <p>
          We specialize in designing and developing web apps that are
          user-friendly, visually appealing, responsive, scalable, and secure.
          We work on front-end, back-end as well as DevOps. We take pride in
          providing exceptional customer service, ensuring that our clients are
          fully satisfied with our work.
        </p>
        <div className="d-flex tech-text-formatting gap-10 mt-2">
          <span className="dot-point"></span>
          <h5 className="mb-0">
            <b>Front-End Development</b>
          </h5>
        </div>
        <p className="description">
          We design and implement user-friendly and visually appealing web apps
          using technologies like HTML, CSS, and JavaScript.
        </p>
        <div className="d-flex tech-text-formatting gap-10 mt-2">
          <span className="dot-point"></span>
          <h5 className="mb-0">
            <b>Back-End Development</b>
          </h5>
        </div>
        <p className="description">
          We offer scalable and secure back-end web app solutions with
          technologies like Ruby on Rails and Node.js.
        </p>
        <div className="d-flex tech-text-formatting gap-10 mt-2">
          <span className="dot-point"></span>
          <h5 className="mb-0">
            <b>DevOps</b>
          </h5>
        </div>
        <p className="description">
          We provide scalable infrastructure deployment and management with
          services such as Amazon Web Services and Google Cloud Platform.
        </p>
      </div>
      {/* second */}
      <div className="text-white p-3">
        <h3>
          <b>Mobile App Development</b>
        </h3>
        <p>
          We have a team of developers focused on creating Android and iOS
          mobile apps with appealing UI/UX designs and multi-platform
          compatibility using Swift, Flutter, and React Native. We specialize in
          identifying the best technology for your project and adapting quickly
          to evolving needs, ensuring we create impactful apps that deliver
          value to your business.
        </p>
        <div className="d-flex tech-text-formatting gap-10 mt-2">
          <span className="dot-point"></span>
          <h5 className="mb-0">
            <b>UI/UX Design</b>
          </h5>
        </div>
        <p className="description">
          We create appealing UI/UX designs for mobile apps with multi-platform
          compatibility using Swift, Flutter, and React Native.
        </p>
        <div className="d-flex tech-text-formatting gap-10 mt-2">
          <span className="dot-point"></span>
          <h5 className="mb-0">
            <b>Android Development</b>
          </h5>
        </div>
        <p className="description">
          We specialize in creating high-quality Android apps that exceed client
          expectations and add value to their business.
        </p>
        <div className="d-flex tech-text-formatting gap-10 mt-2">
          <span className="dot-point"></span>
          <h5 className="mb-0">
            <b>iOS Development</b>
          </h5>
        </div>
        <p className="description">
          We create iOS apps that seamlessly integrate with your business and
          provide your users with a great experience.
        </p>
      </div>
      {/* third */}
      <div className="text-white p-3">
        <h3>
          <b>Quality Assurance</b>
        </h3>
        <p>
          Our team of developers focuses on automated testing to ensure that our
          web apps, mobile apps, and server-side components meet project
          objectives and are free of bugs. We deliver reliable, efficient
          solutions to our clients with almost no risk.
        </p>
        <div className="d-flex tech-text-formatting gap-10 mt-2">
          <span className="dot-point"></span>
          <h5 className="mb-0">
            <b>Automated Testing</b>
          </h5>
        </div>
        <p className="description">
          Our automated testing ensures that our solutions meet project
          objectives and are almost risk-free.
        </p>
        <div className="d-flex tech-text-formatting gap-10 mt-2">
          <span className="dot-point"></span>
          <h5 className="mb-0">
            <b>Iterative Testing</b>
          </h5>
        </div>
        <p className="description">
          Our iterative testing process tests web apps, mobile apps, and
          server-side components throughout development.
        </p>
        <div className="d-flex tech-text-formatting gap-10 mt-2">
          <span className="dot-point"></span>
          <h5 className="mb-0">
            <b>Efficient Solutions</b>
          </h5>
        </div>
        <p className="description">
          Our focus on efficiency in our development process results in reliable
          and efficient solutions for our clients.
        </p>
      </div>
      {/* fourth */}
      <div className="text-white p-3">
        <h3>
          <b>Custom Solutions</b>
        </h3>
        <p>
          We understand that every business has unique requirements. That&apos;s
          why we offer custom development solutions that are tailored to meet
          your specific needs and goals. We work with you to identify your
          requirements and develop solutions that add value to your business.
        </p>
        <div className="d-flex tech-text-formatting gap-10 mt-2">
          <span className="dot-point"></span>
          <h5 className="mb-0">
            <b>Collaborative Approach</b>
          </h5>
        </div>
        <p className="description">
          We work closely with our clients to create custom solutions that meet
          their unique requirements and align with their business goals.
        </p>
        <div className="d-flex tech-text-formatting gap-10 mt-2">
          <span className="dot-point"></span>
          <h5 className="mb-0">
            <b>Flexible Development</b>
          </h5>
        </div>
        <p className="description">
          We are agile and flexible in our approach, ensuring that we can adapt
          quickly to changes in your business requirements.
        </p>
        <div className="d-flex tech-text-formatting gap-10 mt-2">
          <span className="dot-point"></span>
          <h5 className="mb-0">
            <b>Exceptional Customer Service</b>
          </h5>
        </div>
        <p className="description">
          We provide exceptional customer service and support, ensuring that our
          clients are completely satisfied with our work.
        </p>
      </div>
       {/* fifth */}
       <div className="text-white p-3">
        <h3>
          <b>Digital Marketing Services</b>
        </h3>
        <p>
        We recognize that every business has unique digital marketing needs. Our custom solutions are designed to meet your specific goals, ensuring measurable growth and value for your business.
        </p>
        <h3><b>Why Choose Us?</b></h3>
        <hr className="mt-0"/>
        <div className="d-flex tech-text-formatting gap-10 mt-2">
          <span className="dot-point"></span>
          <h5 className="mb-0">
            <b>Collaborative Strategy</b>
          </h5>
        </div>
        <p className="description">
        We work closely with our clients to develop personalized marketing strategies that align with their vision and objectives.
        </p>
        <div className="d-flex tech-text-formatting gap-10 mt-2">
          <span className="dot-point"></span>
          <h5 className="mb-0">
            <b>Dynamic Campaigns</b>
          </h5>
        </div>
        <p className="description">
        Our flexible approach ensures that we can adapt to changes quickly, keeping your campaigns relevant and effective.
        </p>
        <div className="d-flex tech-text-formatting gap-10 mt-2">
          <span className="dot-point"></span>
          <h5 className="mb-0">
            <b>Outstanding Support</b>
          </h5>
        </div>
        <p className="description">
        We pride ourselves on exceptional customer service, providing ongoing support to ensure your satisfaction and success.
        </p>
      </div>
      {/* end */}
    </Slider>
  );
}
