"use client";
import React from "react";
import react from './img/react.png'
import nextjs from './img/next.png'
import angular from './img/angular.png';
import node from './img/node.png';
import express from './img/express.png'
import mongoDB from './img/mongoDB.png'
import postgres from './img/postgres.png'
import mysql from './img/mysql.png'
import firebase from './img/firebase.png'
import azureDevOps from './img/azureDevOps.png'
import kubernetes from './img/kubernetes.png'
import docker from './img/docker.png'
import aws from './img/aws.png'
import spring from './img/spring.png'
import jenkins from './img/jenkins.png'
import digitalmarketing from './img/digitalmarketing.png'
import { handleContextMenu } from "./const";

const techItems = [
  { src: react, alt: 'React', name: 'React' },
  { src: nextjs, alt: 'Next.js', name: 'Next.js' },
  { src: angular, alt: 'Angular.js', name: 'Angular.js' },
  { src: node, alt: 'Node', name: 'Node' },
  { src: express, alt: 'Express', name: 'Express' },
  { src: mongoDB, alt: 'MongoDB', name: 'MongoDB' },
  { src: postgres, alt: 'Postgres', name: 'Postgres' },
  { src: mysql, alt: 'MySQL', name: 'MySQL' },
  { src: firebase, alt: 'Firebase', name: 'Firebase' },
  { src: azureDevOps, alt: 'Azure DevOps', name: 'Azure DevOps' },
  { src: kubernetes, alt: 'Kubernetes', name: 'Kubernetes' },
  { src: docker, alt: 'Docker', name: 'Docker' },
  { src: aws, alt: 'AWS', name: 'AWS' },
  { src: spring, alt: 'Spring Boot', name: 'Spring Boot' },
  { src: jenkins, alt: 'Jenkins', name: 'Jenkins' },
  { src: digitalmarketing, alt: 'Digital Marketing', name: 'Digital Marketing' },
];

const TechItem = ({ src, alt, name, services }) => (
  <div className={services}>
    <img
    onContextMenu={handleContextMenu}
    className="step-icon"
      src={src}
      alt={alt}
    />
    <p className="mb-0 mx-2 font-weight-bold">{name}</p>
  </div>
);

export default function Technologies() {
    let width = window.innerWidth;
  return (
    <div className="row">
      {techItems.map((item, index) => (
        <TechItem
          key={index}
          src={item.src}
          alt={item.alt}
          name={item.name}
          services={`col-sm-6 col-md-3 d-flex tech-text-formatting ${width > 500 ? "mt-5" : "mt-2"}`}
        />
      ))}
    </div>
  );
}
